/*
Expector's personal homepage  Copyright (C) 2022  Expector-Hutch
This program comes with ABSOLUTELY NO WARRANTY; for details type `show w'.
This is free software, and you are welcome to redistribute it
under certain conditions; type `show c' for details.
*/

html,
body {
    height: 100%;
    width: 100%;
}

p.words {
    transition: 0.5s;
    &:hover {
        background: rgba(255, 255, 255, 0.05);
        box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
        border-radius: 30px;
        backdrop-filter: blur(20px);
        text-shadow:
            0 0 7px aqua,
            0 0 10px aqua,
            0 0 21px aqua;
        user-select: text;
        transition: 0.5s;
    }
}

.my-card {
    flex-direction: row;
    box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 8px;
}

aside {
    background: {
        image: url("./123.jpg");
        position: center center;
        repeat: no-repeat;
        size: cover;
        color: rgba(36, 36, 36, 0.08);
    }
    box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 8px;
    width: 320px;
    border: 2px rgba(0, 0, 0, 0.4) solid {
        bottom-left-radius: 11px;
        bottom: 2px rgba(40, 40, 40, 0.35) solid;
        right: none;
        top-left-radius: 11px;
    }
}

main {
    border: {
        top-right-radius: 11px;
        bottom-right-radius: 11px;
        left: none;
    }
    width: 580px;
}

.more {
    display: flex;
    height: 50px;
}

.btn {
    position: relative;
    width: 150px;
    height: 33px;
    margin: 0px 20px;
    &:hover{
        @mixin btntb() {
            width: 80%;
            height: 50%;
            border-radius: 30px;
        }
        &:before {
            @include btntb();
            bottom: 0px;
        }
        &:after {
            @include btntb();
            top: 0px;
        }
        a {
            letter-spacing: 3px;
            &::before {
                transform: skew(45deg) translateX(100px);
            }
        }
    }
    @mixin btnba() {
        content: '';
        position: absolute;
        left: 50%;
        width: 30px;
        height: 10px;
        transform: translateX(-50%);
        border-radius: 10px;
        transition: 0.5s;
    }
    &::before {
        @include btnba();
        bottom: -5px;
    }
    &::after {
        @include btnba();
        top: -5px;
    }
    a {
        $transition: 0.5s;
        text-decoration: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.05);
        box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
        border: {
            top: 1px solid rgba(255, 255, 255, 0.1);
            bottom: 1px solid rgba(255, 255, 255, 0.1);
            radius: 30px;
        }
        color: #fff;
        z-index: 1;
        font-weight: 400;
        letter-spacing: 1px;
        overflow: hidden;
        transition: $transition;
        backdrop-filter: blur(20px);
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
            background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
            transform: skew(45deg) translateX(0);
            transition: $transition;
        }
    }
}
