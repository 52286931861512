/*
Expector's personal homepage  Copyright (C) 2022  Expector-Hutch
This program comes with ABSOLUTELY NO WARRANTY; for details type `show w'.
This is free software, and you are welcome to redistribute it
under certain conditions; type `show c' for details.
*/

@import url(/style/pcmain.scss) (min-width: 960px);
@import url(/style/pemain.scss) (max-width: 959px);

@font-face {
    font-family: 'Lucida Handwriting';
    src: url(/fonts/LHANDW.TTF);
}

$main_color: #66ccff;

html,
body {
    background: {
        color: $main-color;
        image: url("./bg.png");
        position: center center;
        repeat: no-repeat;
        attachment: fixed;
        size: cover;
    }
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    // 滚动条样式
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: $main-color;
    }
}

h1 {
    $shadow-color2: #00ffaa;
    font-size: 24px;
    color: $main-color;
    text-shadow:
        0 0 7px $main-color,
        0 0 10px $main-color,
        0 0 21px $main-color,
        0 0 42px $shadow-color2,
        0 0 82px $shadow-color2,
        0 0 92px $shadow-color2,
        0 0 102px $shadow-color2,
        0 0 151px $shadow-color2;
}

p {
    font-size: 15px;
    word-wrap: break-word;
    color: aqua;
}

#my {
	margin: {
		top: 0;
		bottom: 0;
	}
}

a {
    text-align: center;
    text-decoration-line: none;
    color: white;
    font-size: 16px;
    line-height: 10px;
    &:hover {
        color: white;
        text-shadow:
            0 0 7px #fff,
            0 0 10px #fff,
            0 0 21px #fff;
    }
}

footer {
    p,
    a,
    a:hover {
        font-size: 10px;
        color: darkgray;
    }
}

.btn {
    @mixin btn_style($color) {
        &::before,
        &::after {
            background-color: $color;
            box-shadow: 0 0 5px $color,
                0 0 15px $color,
                0 0 30px $color,
                0 0 60px $color;
        }
    }
    &:nth-child(1) {
        @include btn_style(#ff1f71);
    }
    &:nth-child(2) {
        @include btn_style(#2bd2ff);
    }
    &:nth-child(3) {
        @include btn_style(#11ff45);
    }
}

.my-card {
    display: flex;
    justify-content: center;
    align-items: stretch;
    border-radius: 11px;
}

main {
    display: flex;
    flex-direction: column;
    padding: 33px 30px 0px;
    backdrop-filter: blur(5px);
    background-color: rgba(36, 36, 36, 0.55);
    border: 2px rgba(0, 0, 0, 0.4) solid {
        bottom: 2px rgba(40, 40, 40, 0.35) solid;
        right: 2px rgba(40, 40, 40, 0.35) solid;
        left: none;
    }
}

#friends {
    display: flex;
    align-items: center;
	background: rgba(255, 255, 255, 0.05);
	box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    backdrop-filter: blur(20px);
	text-shadow:
	    0 0 7px aqua,
	    0 0 10px aqua,
	    0 0 21px aqua;
	margin-bottom: 15px;
	padding: 5px;
	p {
		display: inline;
        margin: {
            top: 0;
            bottom: 0;
            left: 1em;
            right: 0.5em;
        }
	}
	img {
		border-radius: 50%;
		height: 20px;
		width: 20px;
		max: {
            height: 20px;
            width: 20px;
        }
        margin: {
            right: 3px;
            left: 3px;
        }
	}
}
