/*
Expector's personal homepage  Copyright (C) 2022  Expector-Hutch
This program comes with ABSOLUTELY NO WARRANTY; for details type `show w'.
This is free software, and you are welcome to redistribute it
under certain conditions; type `show c' for details.
*/

html,
body {
    width: calc(100vw - 4px);
    height: auto;
}

p.words {
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    backdrop-filter: blur(20px);
    text-shadow:
        0 0 7px aqua,
        0 0 10px aqua,
        0 0 21px aqua;
    user-select: text;
}

aside {
    background: {
        image: url("./123.jpg");
        position: center center;
        repeat: no-repeat;
        size: cover;
        color: rgba(36, 36, 36, 0.08);
    }
    width: auto;
    height: calc(100vw - 22px);
    border: 2px rgba(0, 0, 0, 0.4) solid {
        top-left-radius: 11px;
        top-right-radius: 11px;
        bottom: none;
        right: 2px rgba(40, 40, 40, 0.35) solid;
    }
}

main {
    border: {
        bottom-left-radius: 11px;
        bottom-right-radius: 11px;
        top: none;
    }
}

.more {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    height: 150px;
    a {
        height: 44px;
    }
}

.btn {
    position: relative;
    height: 50px;
    @mixin btnba() {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 30px);
        height: 50%;
        border-radius: 30px;
    }
    &::before {
        @include btnba();
        bottom: 10px;
    }
    &::after {
        @include btnba();
        top: 5px;
    }
    a {
        text-decoration: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.05);
        box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
        border: {
            top: 1px solid rgba(255, 255, 255, 0.1);
            bottom: 1px solid rgba(255, 255, 255, 0.1);
            radius: 30px;
        }
        color: #fff;
        z-index: 1;
        font-weight: 400;
        letter-spacing: 1px;
        overflow: hidden;
        transition: 0.5s;
        backdrop-filter: blur(20px);
    }
}

.my-card {
    flex-direction: column;
    width: calc(100% - 6px);
    box-shadow: none;
}
